<template>
  <div>
    v1.8.0 (Commit e7107ab)
  </div>
</template>

<script>
export default {
  name: 'ShowVersion',
}
</script>

