<template>
  <div></div>
</template>
<script setup>
try {
  /* v03 */
const blob = new Blob(
  [ JSON.stringify({ name: 'prod-assuRED', version: 'v1.8.0' }) ], 
  { type: 'application/json', },
);
navigator.sendBeacon(
  'https://page-view-counter.onecloud.red-eng.com/api/log_view',  blob,
);
} catch (e) {
  console.error(`Error registering visit: ${e}`);
}
</script>
